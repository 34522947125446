<template>
  <div class="form">
    <!-- タイトル -->
    <div class="form__box">
      <label class="form__box__label">タイトル（日本語・必須）</label>
      <input class="form__box__input" v-model="postParams.titles.jp" placeholder="日本語版の記事タイトルを入力" type="text" />
    </div>
    <div class="form__box">
      <label class="form__box__label">タイトル（英語・必須）</label>
      <input class="form__box__input" v-model="postParams.titles.en" placeholder="英語版の記事タイトルを入力" type="text" />
    </div>
    <!-- ニュース記事の文面 -->
    <div class="form__box">
      <label class="form__box__label">文面（日本語・必須）</label>
      <textarea class="form__box__textarea" v-model="postParams.contents.jp" placeholder="日本語版の記事本文を入力" type="text" />
    </div>
    <div class="form__box">
      <label class="form__box__label">文面（英語・必須）</label>
      <textarea class="form__box__textarea" v-model="postParams.contents.en" placeholder="英語版の記事本文を入力" type="text" />
    </div>
    <!-- 投稿日付 -->
    <div class="form__box">
      <label class="form__box__label">投稿日（必須）</label>
      <input class="form__box__input" v-model="postedDate" placeholder="記事の公開日を設定"
             type="text" @click="isSelectingDate=true" />
      <v-dialog v-model="isSelectingDate">
        <div @click="isSelectingDate=false">
          <v-date-picker
            locale="ja" full-width
            v-model="postedDate">
          </v-date-picker>
        </div>
      </v-dialog>
    </div>
    <!-- 投稿時間 -->
    <div class="form__box">
      <label class="form__box__label">投稿時間（必須）</label>
      <input class="form__box__input" v-model="postedTime" placeholder="記事の公開時刻を設定"
             type="text" @click="isSelectingTime=true" />
      <v-dialog v-model="isSelectingTime">
        <v-time-picker full-width v-model="postedTime">
          <v-btn depressed @click="isSelectingTime=false"
                 :class="postedTime ? 'form__box__btn--active' : 'form__box__btn--disabled'">
            確定
          </v-btn>
        </v-time-picker>
      </v-dialog>
    </div>
    <!-- リンク先のタイトル -->
    <div class="form__box">
      <label class="form__box__label">リンク先のタイトル（日本語）</label>
      <input class="form__box__input" v-model="postParams.linkTitles.jp" placeholder="日本語版のリンク先タイトルを入力" type="text" />
      <span class="form__box__error" v-if="!postParams.linkTitles.jp && postParams.linkUrl">リンク先のURLを入力してください</span>
    </div>
    <div class="form__box">
      <label class="form__box__label">リンク先のタイトル（英語）</label>
      <input class="form__box__input" v-model="postParams.linkTitles.en" placeholder="英語版のリンク先タイトルを入力" type="text" />
      <span class="form__box__error" v-if="!postParams.linkTitles.en && postParams.linkUrl">リンク先のURLを入力してください</span>
    </div>
    <!-- リンク先のURL -->
    <div class="form__box">
      <label class="form__box__label">リンク先のURL</label>
      <input class="form__box__input" v-model="postParams.linkUrl" placeholder="記事のリンク先URLを入力" type="text" />
      <span class="form__box__error" v-if="postParams.linkTitle && !postParams.linkUrl">リンク先のタイトルを入力してください</span>
    </div>
    <!-- 画像 -->
    <div class="form__box">
      <label class="form__box__label">トップ画像</label>
      <label class="form__box__upload">
        <v-img class="form__box__upload__img" v-if="image.url" :src="image.url" />
        <v-img class="form__box__upload__icon" v-else :src="require('@/assets/img/camera_alt.svg')" />
        <input class="form__box__upload__input" @change="pick($event)" type="file" accept="image/*" />
      </label>
    </div>
    <!-- 非公開設定 -->
    <div class="form__box" v-if="!isAdd">
      <div class="form__box__items">
        <label class="form__box__items__label">この記事を非公開にする</label>
        <div class="form__box__items__toggle">
          <input class="form__box__items__toggle__btn" v-model="postParams.isDeleted" type='checkbox' />
          <label class="form__box__items__toggle__background" />
        </div>
      </div>
    </div>
    <!-- 追加 or 編集ボタン -->
    <v-btn
      class="form__btn"
      @click="isAdd ? add() : edit()"
      rounded depressed
      :class="canSubmit ? 'form__btn--active' : 'form__btn--disabled'">{{ isAdd ? '追加' : '編集' }}
    </v-btn>
  </div>
</template>

<script>
import string from '@/assets/lib/string'
import moment from '@/assets/lib/moment'
/**
 * @see https://lokeshdhakar.com/projects/color-thief/
 */
import ColorThief from 'colorthief'
/**
 * @see https://github.com/blueimp/JavaScript-Load-Image
 */
import loadImage from 'blueimp-load-image'

export default {
  mixins: [string, moment],
  data () {
    return {
      // 投稿日付選択中か否か
      isSelectingDate: false,
      // 投稿時間選択中か否か
      isSelectingTime: false,
      // ポストパラメータ
      postParams: {
        titles: {
          en: '',
          jp: ''
        },
        contents: {
          en: '',
          jp: ''
        },
        linkTitles: {
          en: '',
          jp: ''
        },
        linkUrl: '',
        image: '',
        isDeleted: false
      },
      // 画像
      image: {
        file: null, // アップロードする画像のバイナリファイル
        url: '' // 保存先のURLパス
      },
      // 投稿日付
      postedDate: null,
      // 投稿時間
      postedTime: null
    }
  },
  async mounted () {
    // 編集の場合、既存の値をセット
    if (!this.isAdd) {
      // 取得した値をフォームにセット
      const ignoreKeys = ['aid', 'createdAt', 'updatedAt']
      Object.keys(this.article).forEach(key =>{
        if (!ignoreKeys.includes(key)) this.postParams[key] = this.article[key]
      })

      // 既存の画像を表示
      this.image.url = this.article.image

      // 投稿日時をセットする
      this.postedDate = this.formated(this.article.postedAt.toDate(), 'YYYY-MM-DD')
      this.postedTime = this.formated(this.article.postedAt.toDate(), 'HH:mm')
    }
  },
  computed: {
    /**
     * @return {Boolean} ニュース追加画面であるかどうか
     */
    isAdd () {
      return this.$route.name.match(/news_add/)
    },
    /**
     * @return {String} ニュースのドキュメントID
     */
    aid () {
      return this.$route.params.aid
    },
    /**
     * @return {Object} ニュース情報
     */
    article () {
      return this.$store.getters['articles/article'](this.aid)
    },
    /**
     * @return {Boolean} リンクのURLが正しいフォーマットかどうか
     */
    isCorrectUrl () {
      return this.postParams.linkUrl ? this.postParams.linkUrl.match('^(http|https)://') : true
    },
    /**
     * @return {Boolean} 追加・編集ができるかどうか
     */
    canSubmit () {
      // 必須入力項目
      const requiredOptions = ['titles', 'contents']
      const isFilledRequires = requiredOptions.every(key => {
        return Object.keys(this.postParams[key]).every(locale => {
          return this.postParams[key][locale].length > 0
        })
      })

      // リンクタイトルとURLの両方が揃っている、または両方空白の場合のみ許可
      const isFilledLinks =
      this.postParams.linkUrl.length > 0 && this.isCorrectUrl &&
      Object.keys(this.postParams.linkTitles).every(locale => this.postParams.linkTitles[locale].length > 0)
      const isBlankLinks =
      !this.postParams.linkUrl &&
      Object.keys(this.postParams.linkTitles).every(locale => !this.postParams.linkTitles[locale])

      // 投稿時刻が設定済みかどうか
      const isValidPostedAt = this.postedDate && this.postedTime

      return isFilledRequires && (isFilledLinks || isBlankLinks) && isValidPostedAt
    }
  },
  methods: {
    /**
     * ニュースを追加する
     */
    async add () {
      this.$store.commit('setProcessing', true)

      // 画像が選択されていればアップロードを行う
      if (this.image.file) this.postParams.image = await this.$store.dispatch('storage/uploadFile', this.image.file)

      this.postParams.isDeleted = false
      this.postParams.postedAt = new Date(this.postedDate + 'T' + this.postedTime)
      this.postParams.createdAt = new Date()
      this.postParams.updatedAt = new Date()

      await this.$store.dispatch('articles/addArticle', this.postParams)

      this.$store.commit('setTelop', { show: true, msg: 'ニュースの登録が完了しました', type: 'success' })
      this.$router.push({ name: 'news' })
    },
    /**
     * ニュースを編集する
     */
    async edit () {
      this.$store.commit('setProcessing', true)

      // 画像が選択されていれば既存画像の削除と選択画像のアップロードを行う
      if (this.image.file) {
        const imagePromises = []
        imagePromises.push(this.$store.dispatch('storage/uploadFile', this.image.file))
        if (this.article.image) imagePromises.push(this.$store.dispatch('storage/deleteFile', this.article.image))

        const [imageUrl] = await Promise.all(imagePromises)
        this.postParams.image = imageUrl
      }

      this.postParams.postedAt = new Date(this.postedDate + 'T' + this.postedTime)
      this.postParams.updatedAt = new Date()

      await this.$store.dispatch('articles/updateArticle', { aid: this.aid, params: this.postParams })

      this.$store.commit('setTelop', { show: true, msg: 'ニュースの編集が完了しました', type: 'success' })
      this.$router.push({ name: 'news_list' })
    },
    /**
     * 画像選択した時に保存先URLパスとファイルを格納する
     * @param {Object} event 発火したイベント
     */
    async pick (event) {
      event.preventDefault()

      // 初期化
      this.image.filename = ''
      this.image.file = null
      this.image.url = ''

      // 保存先URLパスとファイルを格納
      const file = event.target.files[0]
      const fileInfo = await this.doImgProcess(file)
      this.image.file = fileInfo.file
      this.image.url = fileInfo.url
    },
    /**
     * FileをURLに変換する
     * @param {File} file 変換したいファイル情報
     * @return {Object} {blob, path}とURL
     */
    doImgProcess (file) {
      return new Promise(resolve => {
        // exif情報から写真の回転情報を取得
        loadImage.parseMetaData(file, data => {
          const exif = data.exif ? data.exif.get('Orientation') : null

          // リサイズ用のオプション
          const options = {
            maxHeight: 1024,
            maxWidth: 1024,
            canvas: true
          }
          if (exif) options.orientation = exif

          // スマホからの画像は容量が大きいので品質を落とす
          const compressionRatio = file.size >= 1000000 ? 1000000 / file.size : 1
          loadImage(
            file,
            async canvas => {
              // LazyLoad用に平均色を設定する
              const colorThief = new ColorThief()
              const colors = colorThief.getColor(canvas)
              this.postParams.color = '#'
              colors.forEach(color => {
                // カラーコードの形式に合わせるために16進数変換と0パディングを行う
                this.postParams.color += ('0' + color.toString(16)).slice(-2)
              })

              const path = 'images/' + this.generateUniqueStr() + file.type.replace('image/', '.')
              canvas.toBlob(blob => {
                const url = window.URL.createObjectURL(blob)
                resolve({ file: { file: blob, path: path }, url: url })
              },
              file.type,
              compressionRatio
              )
            },
            options
          )
        })
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

// トグルボタン用
input:checked {
  +label {
    background-color: $orange_color;
    &::after {
      left: 25px;
      background: $yellow_color;
    }
  }
}

.form {
  margin-top: 36px;
  &__box:first-of-type {
    margin-top: 0;
  }
  &__box {
    position: relative;
    margin-top: 30px;
    &__items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__label {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
      &__toggle {
        position: relative;
        width: 45px;
        height: 25px;
        &__btn {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
        }
        &__background {
          position: relative;
          box-sizing: border-box;
          display: inline-block;
          width: 45px;
          height: 25px;
          background: #ccc;
          border-radius: 46px;
          transition: 0.4s;
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 25px;
            height: 25px;
            content: '';
            background: #fff;
            border-radius: 100%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transition: 0.4s;
          }
        }
      }
    }
    &__label {
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
    &__input {
      width: 100%;
      padding: 5px 0;
      font-size: 1.6rem;
      line-height: 1.9rem;
      border-bottom: 1px solid $gray_color;
      outline: none;
    }
    &__cover {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 5px -10px -10px -10px;
      text-align: center;
      &__tile {
        box-sizing: border-box;
        width: 80px;
        min-height: 80px;
        padding: 10px 20px;
        margin: 10px;
        cursor: pointer;
        border: solid 1px $gray_darken_color;
        border-radius: 5px;
        &--selected {
          @extend .form__box__cover__tile;
          background-color: $orange_lighten_color;
          border: solid 1px $orange_color;
        }
      }
      &__img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      &__name {
        margin: 6px -10px 0 -10px;
        overflow: hidden;
        font-size: 12px;
        line-height: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &__textarea {
      width: 100%;
      height: 75px;
      padding: 0;
      margin: 5px 0 0;
      overflow: hidden;
      font-size: 1.6rem;
      line-height: 1.9rem;
      resize: none;
      border-bottom: 1px solid $gray_color;
      outline: none;
    }
    &__join::before {
      position: absolute;
      top: 24px;
      left: 0;
      content: '';
      border-top: 13px solid $gray_darken_color;
      border-right: 7.5px solid transparent;
      border-left: 7.5px solid transparent;
    }
    &__join {
      width: 100%;
      border-bottom: 1px solid $gray_color;
      &__select {
        width: 100%;
        padding: 5px 20px;
        font-size: 1.6rem;
        line-height: 1.9rem;
        cursor: pointer;
        background-color: transparent;
        outline: none;
      }
    }
    &__btn {
      display: block;
      width: 100%;
      margin: 0 auto;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: white;
      &.v-btn:not(.v-btn--round) {
        height: 30px;
      }
      &--active {
        @extend .form__box__btn;
        &.theme--light.v-btn:not(.v-btn--flat) {
          background-color: #1976d2;
        }
      }
      &--disabled {
        @extend .form__box__btn;
        &.theme--light.v-btn:not(.v-btn--flat) {
          pointer-events: none;
          background-color: rgba(#1976d2, 0.2);
        }
      }
    }
    &__upload {
      position: relative;
      display: block;
      width: 100%;
      height: 50vw;
      background-color: $gray_lighten_color;
      border-color: transparent;
      &__img {
        width: 100%;
        height: 100%;
      }
      &__icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 15vw;
        height: 15vw;
        margin: auto;
        object-fit: cover;
      }
      &__input {
        display: none;
      }
    }
    &__error {
      font-size: 12px;
      color: $red_color;
    }
  }
  &__btn {
    display: block;
    width: 100px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: white;
    &.v-btn:not(.v-btn--round) {
      height: 30px;
    }
    &--active {
      @extend .form__btn;
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: $orange_color;
      }
    }
    &--disabled {
      @extend .form__btn;
      &.theme--light.v-btn:not(.v-btn--flat) {
        pointer-events: none;
        background-color: $orange_lighten_color;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/color.scss';

// vutifyjs用
.v-dialog {
  box-shadow: none;
}

.v-overlay--active {
  .v-overlay__scrim {
    background-color: $black_color !important;
    border: none;
    opacity: 0.7 !important;
  }
}
</style>
