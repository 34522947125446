/**
 * original function library
 * [usage]
 * import string from '@/assets/lib/string'
 * mixins: string
 */
import Vue from 'vue'

export default Vue.extend({
  methods: {
    /**
     * 英数16桁のユニークな文字列を生成する。
     * @return {String} 英数16桁のユニークな文字列
     */
    generateUniqueStr () {
      return new Date().getTime().toString(16) + Math.floor(1000 * Math.random()).toString(16)
    }
  }
})
