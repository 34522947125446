/**
 * original function library
 * [usage]
 * import moment from '@/assets/lib/moment'
 * mixins: [moment]
 */
import moment from 'moment'
import Vue from 'vue'

moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
  monthsShort: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
})

export default Vue.extend({

  methods: {
    /**
     * @param {Date} date フォーマット変換したい日付
     * @param {String} style フォーマット後のスタイル YYYY-MM-DDなど
     * @return {String} フォーマット後の日付
     */
    formated (date, style) {
      return moment(date).format(style)
    }
  }
})
